import React, { Component } from 'react'
import Layout from 'components/layout'
import Title from 'components/title'
import { trFunction } from 'utils/functions'
import { graphql } from 'gatsby'
import { navigate } from 'gatsby'

class WelcomePage extends Component {
  UNSAFE_componentWillMount() {
    if (typeof sessionStorage !== 'undefined') {
      if (!sessionStorage.getItem('loginUser')) {
        navigate('/my-account')
      }
    }
  }

  componentDidMount() {
    if (typeof sessionStorage !== 'undefined') {
      if (sessionStorage.getItem('loginUser')) {
        const loginUser = JSON.parse(sessionStorage.loginUser)
        let p
        if (loginUser.approved) {
          p = document.querySelector('p.not-approved') // if approved, remove not approved
        } else {
          p = document.querySelector('p.approved')
        }
        p.parentNode.removeChild(p)
      }
    }
  }

  render() {
    const data = this.props.data
    const locale = data.site.siteMetadata.locale
    const translations = data.translations
    const tr = (code) => trFunction(code, translations, locale)
    const page = data.thisPage

    let pageHtml = page.body.childMarkdownRemark.html

    let setPath = '/'
    if (typeof sessionStorage !== 'undefined') {
      const lastPath = sessionStorage.getItem('lastPath')
      setPath = lastPath ? lastPath : '/'
    }

    return (
      <Layout>
        <div id="content" className="page-template-page-account">
          <Title title={page.title} />
          <section className="page-content wrap center">
            <div
              dangerouslySetInnerHTML={{
                __html: pageHtml,
              }}
            />
            <a className="button" href={setPath}>
              OK
            </a>
          </section>
        </div>
      </Layout>
    )
  }
}

export default WelcomePage

export const welcomePage = graphql`
  query {
    site {
      siteMetadata {
        locale
      }
    }
    translations: allContentfulStringTranslations {
      edges {
        node {
          code
          en
          zh
        }
      }
    }
    thisPage: contentfulPages(slug: { eq: "welcome" }) {
      title
      slug
      body {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`
